import './App.css';
import { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
    const [gameState, setGameState] = useState("Не определено");
    const [myLogin,setMyLogin] = useState("Неизвестный игрок");
    const [myId, setMyId] = useState("");
    const [isLogined, setisLogined] = useState(false);
    const [votes, setVotes] = useState();
    const [players, setPlayers] = useState();
    const [myVote, setMyVote] = useState(100000);
    const points = ["1000","10000","1","3","5","10","15","20","25","30","35","40","45","50","75","100"]
    const pointNames = []
    pointNames[1]=1;
    pointNames[3]=3;
    pointNames[5]=5;
    pointNames[10]=10;
    pointNames[15]=15;
    pointNames[20]=20;
    pointNames[25]=25;
    pointNames[30]=30;
    pointNames[35]=35;
    pointNames[40]=40;
    pointNames[45]=45;
    pointNames[50]=50;
    pointNames[75]=75;
    pointNames[100]=100;
    pointNames[1000]='Кофе';
    pointNames[10000]='Дичь какая-то';
    pointNames[100000]='Не голосовал';
    const autocheckGameState = () => {
        const dataPost = {"act":"getgame"}
        axios
            .post(
                "https://89.108.77.39/poker/game.php/",
                dataPost,
                { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
            )
            .then(
                (response) => {
                    setGameState(response.data.game);
                    if (response.data.game==="Finished") {
                        const votes1 = {}
                        response.data.votes.forEach((el) => votes1[el.player] = el.vote)
                        setVotes(votes1)
                    }
                    if (response.data.game==="Running") {
                        const players1 = {}
                        response.data.players.forEach((el) => players1[el.player] = el.vote)
                        setPlayers(players1)
                    }
                }
            )
    }
    useEffect(() => {
        const intervalCall = setInterval(() => {
            autocheckGameState();
        }, 3000);
        return () => {

            // clean up
            clearInterval(intervalCall);
        };
    }, []);
    const logout = (e) => {
        e.preventDefault();
        setisLogined(false);
        setMyLogin("");
        setMyId("");
        setMyVote("");
    }
    const loginasuser = (e) => {
        e.preventDefault();
        const login = e.target.login.value
        const dataPost = {act: "login", login : login}
        axios
            .post(
                "https://89.108.77.39/poker/game.php/",
                dataPost,
                { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
            )
            .then(
                (response) => {
                    if (response.data.status!=="error") {
                        setMyLogin(response.data.playerName)
                        setMyId(response.data.playerId);
                        setisLogined(true);
                    }
                })
    }
    const makeVote = (e) => {
        e.preventDefault()
        const vote = e.target.value
        const dataPost = {act: "vote", userid: myId, vote: vote}
        axios
            .post(
                "https://89.108.77.39/poker/game.php/",
                dataPost,
                { headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
            )
            .then(
                (response) => {
                    if (response.data.status!=="error") {
                        setMyVote(response.data.acceptedVote);
                    }
                })
    }
       return (
        <div className="App">
            <header className="App-header">
                <p>ЕЛК покер</p>
                <p>Статус голосования - {gameState}
                    <br />
                    {isLogined &&
                        <span>
                            Я - {myLogin}
                            <button onClick={logout}> Выйти</button>
                            <br />
                            Мой голос - {pointNames[myVote]}
                            <br />
                            {gameState==="Finished" &&
                                <span>
                                    Голоса - {Object.keys(votes).map(key => <span><br />{key} - {pointNames[votes[key]]} </span>)}
                                </span>
                            }
                            {gameState==="Running" &&
                                <span>
                                    Игроки - {Object.keys(players).map((key,value) => <span><br />{key} - {players[key]==='voted' ? 'Проголосовал' : 'Не голосовал'}  </span>)}
                                </span>
                            }
                            </span>
                        }
                </p>

                {!isLogined &&
                    <form onSubmit={loginasuser}>
                        <input type='text' name='login' placeholder='Ваше имя'></input>
                        <button type='submit'>Войти в игру</button>
                    </form>
                }
                {isLogined && gameState==="Running" &&   <span>
                    <p>Мой голос</p>
                    {points.map((value, key) =>
                    <button className='buttonVote' name = {key} value={value} onClick ={makeVote}>{pointNames[value]}</button>)}
                    </span>
                }

            </header>
        </div>
    );
};

export default App;
